<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title
        ><span v-if="isTransition">Transition to</span><span v-else>Update</span>
        {{ selectedProduct.title }}</v-toolbar-title
      >
      <v-spacer />
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div>
        <span class="font-weight-bold">Choose your desired payment frequency</span>
        <j-select
          v-model="selectedVariantCode"
          item-text="title"
          item-value="code"
          dense
          :items="billingPeriods"
          class="pt-0 mt-0"
        />

        <j-checkbox
          v-if="selectedProduct.code == 'jeff-life'"
          v-model="isDiscovery"
          :label="`I'm a Discovery Vitality member and would like a ${discoveryDiscountPercent}% discount on ${selectedProduct.title}`"
          hide-details
          class="mt-n6 mb-4"
        ></j-checkbox>

        <span class="font-weight-bold">Apply a discount to your next payment</span>
        <div class="d-flex">
          <j-text-field
            v-model="voucher.code"
            placeholder="Enter voucher code"
            dense
            outlined
            :error-messages="voucher.error"
          ></j-text-field>
          <div class="ml-4">
            <v-btn
              v-if="!voucher.applied && !voucher.error"
              :disabled="!voucher.code || !selectedVariant"
              class="secondary--text"
              rounded
              @click="applyVoucher"
            >
              <strong>Apply</strong>
            </v-btn>
            <v-btn v-else rounded class="secondary--text" @click="clearVoucher">
              <strong>Remove</strong>
            </v-btn>
          </div>
        </div>
        <j-alert v-if="voucher.applied" class="mt-n3">
          The voucher will be applied on your next payment as soon as you confirm</j-alert
        >

        <div v-if="coachRequired">
          <span class="font-weight-bold">Choose your coach</span>
          <v-row>
            <v-col cols="12" v-for="coach in coaches" :key="coach.user.id" class="mt-4">
              <choose-coach-listing-card
                @choose="chooseCoach"
                :coach-profile="coach"
                :is-selected="isSelected(coach.user.id)"
                class="mb-4"
              />
            </v-col>
          </v-row>
        </div>

        <template v-if="cartTotal">
          <h4 class="mb-1">Summary</h4>
          <p>On {{ endDate }}, the following will happen:</p>
          <ul class="mt-n3 mb-2">
            <li>
              Your previous <span v-if="isTransition">subscription</span><span v-else>payment plan</span> will end
            </li>
            <li>
              You'll be charged R {{ cartTotal.amountAfterDiscounts }}, starting your new
              <span v-if="isTransition">subscription</span><span v-else>payment plan</span>
            </li>
            <li v-if="isTransition">You will transition to {{ selectedProduct.title }}</li>
            <li v-else>You will remain on {{ selectedProduct.title }}</li>
          </ul>
          Thereafter:
          <ul class="mb-6">
            <li>Your payment will be R {{ cartTotal.recurringAmount }}</li>
          </ul>
        </template>
      </div>

      <div class="d-flex justify-space-between">
        <j-btn @click="close()" tertiary>Cancel</j-btn>
        <j-btn class="ml-2" :disabled="confirmDisabled" primary @click="confirm">Confirm</j-btn>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import { VOUCHER_ERRORS } from "@/constants/store/strings.js";
import { CHECK_VOUCHER_QUERY } from "@/graphql/queries/store";
import { CART_TOTAL } from "@/graphql/queries/store";
import StaffMixin from "@/mixins/StaffMixin";
import ChooseCoachListingCard from "@/components/coach/ChooseCoachListingCard";

export default {
  name: "TransitonSubscriptionDialog",
  mixins: [StaffMixin],
  components: { ChooseCoachListingCard },
  props: {
    billingProducts: {
      type: [Object, Array],
      required: true,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
    // the current subscription variant
    currentVariant: {
      type: Object,
      required: false,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedVariantCode: null,
      voucher: {
        code: null,
        error: null,
        applied: false,
        discounts: [],
      },
      cartTotal: null,
      isDiscovery: false,
      discoveryDiscountPercent: 25,
      recurringDiscountCode: "jeff-life-vitality",
      userType: "Coach",
      selectedCoachId: null,
    };
  },
  watch: {
    selectedVariantCode: function () {
      this.applyVoucher();
      this.calculateCartTotal();
    },
    isDiscovery: function () {
      this.applyVoucher();
      this.calculateCartTotal();
    },
  },
  computed: {
    confirmDisabled() {
      return !this.selectedVariantCode || !!this.voucher.error || (this.coachRequired && !this.selectedCoachId);
    },
    coachRequired() {
      return this.selectedProduct?.code?.includes("1-on-1");
    },
    isTransition() {
      // this dialog is either for a transiton to a new product, or a change of existing product billing period
      // the copy changes accordingly
      return this.currentVariant?.product?.code != this.selectedProduct.code;
    },
    billingPeriods() {
      const billingPeriods = [];
      // store a key value pair of billing prices per code
      const product = this.billingProducts.edges.find((obj) => {
        return obj.node.code == this.selectedProduct.code && !obj.node.isHidden;
      });
      product.node.productvariantSet.edges.forEach((variant) => {
        const price = variant.node.productvariantpriceSet.find((price) => {
          return price.currency == "ZAR";
        });

        if (!variant?.node?.isHidden && variant?.node?.code != this.currentVariant?.code) {
          billingPeriods.push({
            id: decodeId(variant.node.id),
            code: variant.node.code,
            title: `${variant.node.title} - ${price.currency} ${price.price}`,
            price: `${price.currency} ${price.price}`,
            shortTitle: `${variant.node.title}`,
            shortPrice: `${price.price}`,
            currency: `${price.currency}`,
          });
        }
      });
      return billingPeriods;
    },
    selectedVariant() {
      return this.billingPeriods.find((period) => {
        return period.code == this.selectedVariantCode;
      });
    },
  },
  methods: {
    isSelected(userId) {
      return decodeId(this.selectedCoachId) == decodeId(userId);
    },
    chooseCoach(userId) {
      this.selectedCoachId = decodeId(userId);
    },
    confirm() {
      let data = {
        variantCode: this.selectedVariantCode,
        discountCode: this.voucher.code,
        recurringDiscountCode: this.isDiscovery ? this.recurringDiscountCode : "",
        transitionCoachId: this.selectedCoachId,
      };
      this.$emit("confirm", data);
      this.selectedVariantCode = null;
      this.isDiscovery = false;
      this.clearVoucher();
    },
    close() {
      this.$emit("closed", this.selectedVariantCode);
      this.selectedVariantCode = null;
      this.isDiscovery = false;
      this.clearVoucher();
    },
    applyVoucher() {
      if (!this.voucher?.code) {
        return;
      }
      this.$apollo
        .query({
          query: CHECK_VOUCHER_QUERY,
          fetchPolicy: "no-cache",
          variables: {
            code: this.voucher.code,
            variantIds: [this.selectedVariant.id],
          },
        })
        .then((response) => {
          if (!response.loading) {
            let data = response.data;
            if (data.voucherDiscounts.error) {
              let error = data.voucherDiscounts.error;
              this.voucher.error = VOUCHER_ERRORS[error];
              return;
            }
            let applicableDiscounts = data.voucherDiscounts.discounts.filter((element) => element.error == null);
            let supercededDiscounts = data.voucherDiscounts.discounts.filter(
              (element) => element.error == "PROMO_HIGHER"
            );
            if (supercededDiscounts.length > 0) {
              this.voucher.error = VOUCHER_ERRORS["PROMO_HIGHER"];
              return;
            }

            if (applicableDiscounts.length === 0) {
              this.voucher.error = VOUCHER_ERRORS["NO_APPLICABLE_PRODUCTS"];
              return;
            }
            this.voucher.applied = true;
            this.voucher.discounts = applicableDiscounts;

            this.calculateCartTotal();
          }
        });
    },
    clearVoucher() {
      this.voucher.applied = false;
      this.voucher.code = "";
      this.voucher.error = null;
      this.voucher.discounts = null;
    },
    calculateCartTotal() {
      if (!this.selectedVariant?.id) {
        return;
      }
      this.$apollo
        .query({
          query: CART_TOTAL,
          variables: {
            productVariantIds: [this.selectedVariant.id],
            voucherCode: this.voucher?.code,
            currency: "ZAR",
            recurringDiscountCode: this.isDiscovery ? this.recurringDiscountCode : "",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data.cartTotal) {
            this.cartTotal = response.data.cartTotal;
            this.cartTotal.totalDiscount = this.cartTotal.baseAmount - this.cartTotal.amountAfterDiscounts;
          }
        });
    },
  },
};
</script>
